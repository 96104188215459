import { createSelector } from 'reselect';
import pickBy from 'lodash/pickBy';
import identity from 'lodash/identity';
import get from 'lodash/get';
import reverse from 'lodash/reverse';
import queryString from 'query-string';
import values from 'lodash/values';
import flatten from 'lodash/flatten';
import orderBy from 'lodash/orderBy';
import groupBy from 'lodash/groupBy';
import keys from 'lodash/keys';
import last from 'lodash/last';
import initial from 'lodash/initial';
import find from 'lodash/find';
import includes from 'lodash/includes';
import { startOfDay } from 'date-fns';
import { camelizeKeys } from 'humps';
import { formatDate } from 'utils';
import { getApiQueryFromPaginationQuery } from 'utils/routes';

export const getParams = (state, { match }) => match.params;
export const getQuery = (state, { location: { search } }) => search;
export const getProps = (state, props) => props;

/**
 * Select top-level entities
 */

export const getPlaces = state => state.places;
export const getUi = state => state.ui;
export const getUser = state => state.user;
export const getUsers = state => state.users;
export const getReports = state => state.reports;
export const getContracts = state => state.contracts;
export const getConversations = state => state.conversations;
export const getAccounts = state => state.accounts;
export const getFeed = state => state.feed;
export const getSupport = state => state.support;
export const getForum = state => state.forum;
export const getExpenses = state => state.expenses;
export const getCommunication = state => state.communication;

/**
 * Select an top-level entity with route params.
 */

export const getPlace = (state, { match }) => get(state, `places.placeById.${get(match, 'params.id')}`);

/**
 * Lists
 */

export const getSelectedPlace = createSelector(
   [getPlaces, getUi],
   ({ placeById, equipmentById, equipmentsIdsByPlaceId, invitationsByPlaceId }, ui) => {
      const { selectedPlaceId } = ui;
      const canManageInvitation = invitationsByPlaceId?.[selectedPlaceId]?.canManageInvitation || false;
      const invitedContacts = invitationsByPlaceId?.[selectedPlaceId]?.contacts || [];

      if (!selectedPlaceId) {
         return {
            equipments: [],
            reports: [],
            canManageInvitation,
            invitedContacts,
            agency: {},
         };
      }

      return {
         ...placeById[selectedPlaceId],
         equipments: (equipmentsIdsByPlaceId[selectedPlaceId] || []).map(id => equipmentById[id]),
         canManageInvitation,
         invitedContacts,
      };
   }
);

export const getSelectedPlaceDefaultIdentity = createSelector(
   [getSelectedPlace, getUser],
   (place, { identityById = {} }) => (Boolean(place) ? identityById[place.defaultIdentity] : null)
);

export const getCurrentUser = createSelector(
   [getUser, getUsers, getSelectedPlaceDefaultIdentity, getUi],
   ({ userId, identitiesIdsByPlaceId, identityById }, { userById }, currentIdentity, { selectedPlaceId }) => {
      const user = userById[userId] || {};

      return {
         ...user,
         currentIdentity,
         availableIdentities: ((identitiesIdsByPlaceId || {})[selectedPlaceId] || []).map(id => identityById[id]),
      };
   }
);

export const getUserPlaces = createSelector(
   [getPlaces, getUi, getSelectedPlace],
   ({ placesIds, placeById, invitationsByPlaceId }, { selectedPlaceId }, selectedPlace) => ({
      selected: selectedPlace,
      other: placesIds
         .map(id => {
            const place = placeById[id];
            const canManageInvitation = Boolean(get(invitationsByPlaceId[id], 'canManageInvitation'));
            const invitedContacts = get(invitationsByPlaceId[id], 'contacts') || [];

            return {
               ...place,
               canManageInvitation,
               invitedContacts,
            };
         })
         .filter(place => selectedPlaceId !== place.id && Boolean(place.id)),
   })
);

export const selectPlace = createSelector([getUserPlaces, getProps], (places, { placeId }) =>
   find(places, ({ id }) => Number(id) === Number(placeId))
);

export const selectPlaceReports = createSelector(
   [getReports, getSelectedPlace, getUsers, getPlaces, getCurrentUser],
   (
      { reportById, reportEventById, reportsIdsByPlaceId, reportCategoryById },
      selectedPlace,
      { userById },
      { equipmentById },
      currentUser
   ) => {
      if (!reportsIdsByPlaceId || !selectedPlace || !selectedPlace.id) {
         return { openReports: [], closedReports: [] };
      }

      const reports = (reportsIdsByPlaceId[selectedPlace.id] || []).map(id => {
         const report = reportById[id];
         const reportEvents = report.reportEvents.map(reid => reportEventById[reid]);

         return {
            ...report,
            author:
               report.author !== currentUser.id
                  ? userById[report.author]
                  : {
                       ...userById[report.author],
                       ...currentUser.currentIdentity,
                    },
            category: reportCategoryById[report.category],
            equipment: equipmentById[report.equipmentId] || {},
            reportEvents,
         };
      });

      const openReports = reports.filter(({ state }) => state === 'opened' || state === 'acknowledged');
      const closedReports = reports.filter(({ state }) => state === 'resolved');

      return { openReports, closedReports };
   }
);

export const selectEquipmentReports = createSelector(
   [getReports, getParams, getUsers, getCurrentUser],
   (
      { reportById, reportEventById, reportsIdsByEquipmentId, reportCategoryById },
      { equipmentId },
      { userById },
      currentUser
   ) => {
      if (!reportsIdsByEquipmentId || !equipmentId) {
         return { opentReports: [], closedReports: [] };
      }

      const reports = (reportsIdsByEquipmentId[equipmentId] || []).map(id => {
         const report = reportById[id];
         const reportEvents = report.reportEvents.map(reid => reportEventById[reid]);

         return {
            ...report,
            author:
               report.author !== currentUser.id
                  ? userById[report.author]
                  : {
                       ...userById[report.author],
                       ...currentUser.currentIdentity,
                    },
            category: reportCategoryById[report.category],
            reportEvents,
         };
      });

      const openReports = reports.filter(({ state }) => state === 'opened' || state === 'acknowledged');
      const closedReports = reports.filter(({ state }) => state === 'resolved');

      return { openReports, closedReports };
   }
);

export const getAgnosticUserPlaces = createSelector([getPlaces], ({ placesIds, placeById }) =>
   placesIds.map(id => placeById[id])
);

export const selectReport = createSelector(
   [getReports, getParams, getPlaces, getUsers, getCurrentUser],
   (
      { reportById, reportEventById, reportCategoryById, witnessesIdsByReportId },
      { reportId },
      { placeById, equipmentById },
      { userById },
      currentUser
   ) => {
      const report = reportById[reportId];
      const witnessesIds = witnessesIdsByReportId[reportId] || [];
      const witnesses = witnessesIds.map(wid => userById[wid]);

      if (!report) {
         return {
            witnesses,
         };
      }

      return {
         ...report,
         witnesses,
         author:
            report.author !== currentUser.id
               ? userById[report.author]
               : {
                    ...userById[report.author],
                    ...currentUser.currentIdentity,
                 },
         reportEvents: report.reportEvents.map(reid => {
            const reportEvent = reportEventById[reid] || {};

            return {
               ...reportEvent,
               author:
                  reportEvent.author !== currentUser.id
                     ? userById[reportEvent.author]
                     : {
                          ...userById[reportEvent.author],
                          ...currentUser.currentIdentity,
                       },
            };
         }),
         place: placeById[report.placeId],
         equipment: equipmentById[report.equipmentId],
         category: reportCategoryById[report.category],
      };
   }
);

export const selectEquipment = createSelector(
   [getPlaces, getParams, getReports],
   ({ equipmentById }, { equipmentId }, { reportsIdsByEquipmentId }) => {
      return equipmentById[equipmentId] || {};
   }
);

export const selectPlaceCoownershipFolders = createSelector(
   [getPlaces, getSelectedPlace],
   ({ coownershipDocumentById, coownershipFolderById, coownershipFoldersIdsByPlaceId }, { id }) => {
      const coownershipFoldersIds = coownershipFoldersIdsByPlaceId[id] || [];

      return coownershipFoldersIds.map(cfid => {
         const coownershipFolder = coownershipFolderById[cfid];

         return {
            ...coownershipFolder,
            coownershipDocuments: (coownershipFolder.coownershipDocuments || []).map(
               cdid => coownershipDocumentById[cdid]
            ),
         };
      });
   }
);

export const selectPlaceMaintenanceContractsCategories = createSelector(
   [getPlaces, getSelectedPlace],
   ({ maintenanceContractsCategoriesIdsByPlaceId, maintenanceContractsCategoryById }, { id }) => {
      const maintenanceContractsIds = maintenanceContractsCategoriesIdsByPlaceId[id] || [];

      return maintenanceContractsIds.map(mcid => maintenanceContractsCategoryById[mcid]);
   }
);

export const selectPlaceMaintenanceContract = createSelector(
   [getPlaces, getParams],
   (
      {
         maintenanceContractDocumentById,
         maintenanceContractById,
         maintenanceContractsIdByMaintenanceContractsCategoryId,
      },
      { contractId }
   ) => {
      const maintenanceContractId = maintenanceContractsIdByMaintenanceContractsCategoryId[contractId];

      if (!maintenanceContractId) {
         return {};
      }

      const maintenanceContract = maintenanceContractById[maintenanceContractId] || {};

      return {
         ...maintenanceContract,
         maintenanceContractDocuments: maintenanceContract.maintenanceContractDocuments.map(
            mcdid => maintenanceContractDocumentById[mcdid]
         ),
      };
   }
);

export const selectPlaceMaintenanceContractCategory = createSelector(
   [getPlaces, getParams],
   ({ maintenanceContractsCategoryById }, { contractId }) => maintenanceContractsCategoryById[contractId] || {}
);

export const selectPlaceContacts = createSelector(
   [getUi, getUsers, getQuery],
   ({ contactsSearch = '', selectedPlaceId }, { userById, contactsPagination }, search) => {
      if (search === '') {
         search = '?';
      }

      const { page, ...parsedQuery } = queryString.parse(getApiQueryFromPaginationQuery(search));

      const query = `?${queryString.stringify(
         camelizeKeys(
            pickBy(
               {
                  ...parsedQuery,
                  search: contactsSearch.length > 2 ? contactsSearch.toLowerCase() : null,
                  sort: 'lastName,firstName',
               },
               identity
            )
         )
      )}`;

      const contactsIds = get(contactsPagination, `[${selectedPlaceId}][${query}].idsByPage[${page || 1}]`);

      if (!contactsIds) {
         return [];
      }

      return contactsIds.map(id => userById[id]);
   }
);

export const selectContactsPagination = createSelector(
   [getUsers, getQuery, getUi],
   ({ contactsPagination }, search, { selectedPlaceId, contactsSearch }) => {
      const { page, ...parsedQuery } = queryString.parse(getApiQueryFromPaginationQuery(search));

      const query = `?${queryString.stringify(
         camelizeKeys(
            pickBy(
               {
                  ...parsedQuery,
                  search: (contactsSearch || '').length > 2 ? contactsSearch.toLowerCase() : null,
                  sort: 'lastName,firstName',
               },
               identity
            )
         )
      )}`;

      return get(contactsPagination, `${selectedPlaceId}[${query}]`) || {};
   }
);

export const selectPlaceTreasuries = createSelector(
   [getPlaces, getUi],
   ({ treasuryById, treasuriesIdsByPlaceId }, { selectedPlaceId }) =>
      (treasuriesIdsByPlaceId?.[selectedPlaceId] ?? []).map(treasuryId => treasuryById[treasuryId])
);

export const selectPlaceBudgets = createSelector(
   [getPlaces, getUi],
   ({ budgetById, budgetsIdsByPlaceIdAndByFiscalYearId }, { selectedPlaceId, selectedFiscalYears = {} }) => {
      const selectedFiscalYearId = selectedFiscalYears[selectedPlaceId];

      const budgetsIds =
         get(budgetsIdsByPlaceIdAndByFiscalYearId, `[${selectedPlaceId}][${selectedFiscalYearId}]`) || [];

      const budgets = budgetsIds.map(id => budgetById[id]);

      const totalBudget = {
         allocated: budgets.reduce((acc, curr) => acc + curr.allocatedAmount, 0),
         spent: budgets.reduce((acc, curr) => acc + curr.spentAmount, 0),
      };

      return { budgets, totalBudget };
   }
);

export const selectPlaceTreasuriesRequestStatus = createSelector(
   [getPlaces, getUi],
   ({ treasuriesRequestsStatusesByPlaceId }, { selectedPlaceId }) =>
      treasuriesRequestsStatusesByPlaceId[selectedPlaceId] || 'pending'
);

export const selectBudget = createSelector([getPlaces, getParams], ({ budgetById }, { id }) => budgetById[id]);

export const selectPlaceBudgetAccountEntries = createSelector(
   [getAccounts, getParams],
   ({ accountPlaceEntriesIdsByBudgetId, accountPlaceEntryById }, { id }) => {
      const accountPlaceEntriesIds = accountPlaceEntriesIdsByBudgetId[id] || [];
      return accountPlaceEntriesIds.map(apeid => accountPlaceEntryById[apeid]);
   }
);

export const selectPlaceBudgetProviderAccountEntries = createSelector(
   [getAccounts, getParams],
   (
      {
         orphanAccountPlaceEntriesIdsByBudgetId,
         providerAccountPlaceEntriesIdsByBudgetId,
         providerAccountPlaceEntryById,
         accountPlaceEntryById,
      },
      { id }
   ) => {
      const orphanAccountPlaceEntries = orphanAccountPlaceEntriesIdsByBudgetId[id];
      const providerAccountPlaceEntriesIds = providerAccountPlaceEntriesIdsByBudgetId[id] || [];

      return [
         {
            id: '*',
            displayName: 'Divers',
            accountPlaceEntries: (orphanAccountPlaceEntries || []).map(id => accountPlaceEntryById[id]),
         },
      ].concat(
         providerAccountPlaceEntriesIds.map(papeid => {
            const providerAccountPlaceEntry = providerAccountPlaceEntryById[papeid];

            return {
               ...providerAccountPlaceEntry,
               accountPlaceEntries: providerAccountPlaceEntry.accountPlaceEntries.map(
                  apeid => accountPlaceEntryById[apeid]
               ),
            };
         })
      );
   }
);

export const selectPlaceBudgetAccountCodeAccountEntries = createSelector(
   [getAccounts, getParams],
   (
      { accountCodeAccountPlaceEntriesIdsByBudgetId, accountCodeAccountPlaceEntryById, accountPlaceEntryById },
      { id }
   ) => {
      const accountCodeAccountPlaceEntriesIds = accountCodeAccountPlaceEntriesIdsByBudgetId[id] || [];
      return accountCodeAccountPlaceEntriesIds.map(acapeid => {
         const accountCodeAccountPlaceEntry = accountCodeAccountPlaceEntryById[acapeid];
         return {
            ...accountCodeAccountPlaceEntry,
            accountPlaceEntries: accountCodeAccountPlaceEntry.accountPlaceEntries.map(
               apeid => accountPlaceEntryById[apeid]
            ),
         };
      });
   }
);

export const selectPlaceFiscalYears = createSelector(
   [getPlaces, getUi],
   ({ fiscalYearsIdsByPlaceId, fiscalYearById }, { selectedPlaceId }) =>
      orderBy(
         (fiscalYearsIdsByPlaceId[selectedPlaceId] || []).map(id => fiscalYearById[id]),
         'startDate',
         'desc'
      )
);

export const selectUserContracts = createSelector(
   [getContracts, getPlaces, getUser],
   (
      { parcelById, contractsIds, contractById, moneyOrderById, moneyOrderIdByContractId },
      { placeById },
      { identityById }
   ) => {
      const contracts = contractsIds
         .map(id => contractById[id])
         .map(contract => ({
            ...contract,
            parcels: contract.parcels.map(id => parcelById[id]),
            moneyOrder: moneyOrderById[moneyOrderIdByContractId[contract.id]],
            identity: identityById[contract.identity],
         }));

      const contractsByPlaceDisplayName = groupBy(contracts, 'placeId');
      const output = keys(contractsByPlaceDisplayName).map(placeId => ({
         placeId,
         place: placeById[placeId],
         contracts: contractsByPlaceDisplayName[placeId] || [],
      }));

      return output;
   }
);

export const selectUserSelectedPlaceContracts = createSelector(
   [selectUserContracts, getUi],
   (groupedContracts, { selectedPlaceId }) =>
      get(
         find(groupedContracts, ({ placeId }) => Number(placeId) === Number(selectedPlaceId)),
         'contracts'
      ) || []
);

export const selectPlaceUserContracts = createSelector(
   [selectUserContracts, getProps],
   (groupedContracts, { placeId }) =>
      groupedContracts.filter(({ contracts }) =>
         includes(
            contracts.map(({ placeId }) => placeId),
            Number(placeId)
         )
      )
);

export const selectContract = createSelector(
   [getContracts, getProps, getUser],
   ({ parcelById, contractById, moneyOrderById, moneyOrderIdByContractId }, { contractId }, { identityById }) => ({
      ...contractById[contractId],
      moneyOrder: moneyOrderById[moneyOrderIdByContractId[contractId]],
      parcels: ((contractById[contractId] || {}).parcels || []).map(pid => parcelById[pid]),
      identity: identityById[(contractById[contractId] || {}).identity],
   })
);

export const selectContractDocuments = createSelector(
   [getContracts, getParams],
   ({ contractDocumentsFoldersIdByContractId, contractDocumentsFolderById, contractDocumentById }, { id }) => {
      const foldersIds = contractDocumentsFoldersIdByContractId[id] || [];
      const folders = foldersIds.map(id => contractDocumentsFolderById[id]);

      return folders.map(folder => ({
         ...folder,
         contractDocuments: folder.contractDocuments.map(cdid => contractDocumentById[cdid]),
      }));
   }
);

export const selectContractAccountEntries = createSelector(
   [getContracts, getParams],
   ({ accountEntryById, accountEntriesIdsByContractId }, { id }) => {
      const accountEntriesIds = accountEntriesIdsByContractId[id] || [];

      return accountEntriesIds.map(aeid => accountEntryById[aeid]);
   }
);

export const selectPlaceConversations = createSelector(
   [getUi, getConversations, getUsers, getQuery, getUser],
   (
      { selectedPlaceId, conversationsSearch },
      { conversationById, conversationsPagination },
      { userById },
      search,
      { userId }
   ) => {
      if (search === '') {
         search = '?';
      }

      const parsedQuery = queryString.parse(search);

      const query =
         '?' +
         queryString.stringify(
            pickBy(
               {
                  ...(parsedQuery || {}),
                  sort: '-updatedAt',
                  search: (conversationsSearch || '').toLowerCase(),
                  notArchived: userId,
               },
               identity
            )
         );

      const pagination = get(conversationsPagination, `${selectedPlaceId}[${query}].idsByPage`) || {};

      const conversationsIds = flatten(values(pagination));

      if (!conversationsIds) {
         return [];
      }

      return orderBy(
         conversationsIds
            .map(id => conversationById[id])
            .map(conversation => ({
               ...conversation,
               members: conversation.members.map(mid => userById[mid]),
            })),
         'updatedAt',
         'desc'
      );
   }
);

export const selectConversationsPagination = createSelector(
   [getConversations, getQuery, getUi],
   ({ conversationsPagination }, search, { selectedPlaceId, conversationsSearch }) => {
      const parsedQuery = queryString.parse(search);

      const query = `?${queryString.stringify(
         pickBy(
            {
               ...(parsedQuery || {}),
               sort: '-updatedAt',
               search: (conversationsSearch || '').toLowerCase(),
            },
            identity
         )
      )}`;

      return get(conversationsPagination, `${selectedPlaceId}[${query}]`) || {};
   }
);

export const selectConversation = createSelector(
   [getConversations, getParams, getUsers],
   ({ conversationById }, { id }, { userById }) => {
      const conversation = conversationById[id] || {};

      return {
         ...conversation,
         members: (conversation.members || []).map(mid => userById[mid]),
      };
   }
);

export const selectConversationMessages = createSelector(
   [getConversations, getParams, getUsers, getCurrentUser],
   ({ messagesIdsByConversationId, messageById }, { id }, { userById }, currentUser) => {
      const messagesIds = messagesIdsByConversationId[id] || [];

      const messages = messagesIds
         .map(id => messageById[id])
         .map(message => ({
            ...message,
            author:
               message.author !== currentUser.id
                  ? userById[message.author]
                  : {
                       ...userById[message.author],
                       ...currentUser.currentIdentity,
                    },
         }));

      /**
       * Format conversation messages data to be:
       *
       * [1] grouped by days,
       * [2] stacked by authors,
       * [3] ordered chronologically.
       */

      // [1]
      const groupedMessages = groupBy(messages, ({ createdAt }) =>
         formatDate(startOfDay(createdAt), "yyyy-MM-dd'T'HH:mm:ss.SSSZ")
      );

      return reverse(keys(groupedMessages)).reduce(
         (acc, day) => [
            {
               day,
               authors: reverse(groupedMessages[day] || []).reduce((acc, { author, ...message }) => {
                  if (get(last(acc), 'author.id') === author.id) {
                     // [2]
                     return initial(acc).concat({
                        ...last(acc),
                        messages: [...last(acc).messages, message], // [3]
                     });
                  }

                  return [...acc, { author, fromMe: message.fromMe, messages: [message] }];
               }, []),
            },
            ...acc,
         ],
         []
      );
   }
);

export const selectTreasuryAccountPlaceEntries = createSelector(
   [getAccounts, getQuery, getProps, selectPlaceTreasuries],
   ({ accountPlaceEntryById, accountPlaceEntriesPagination }, search, { target }, treasuries) => {
      const treasuryId = get(treasuries, `[${target}].id`);

      if (!treasuryId) {
         return [];
      }

      if (search === '') {
         search = '?';
      }

      const { page, ...parsedQuery } = {
         ...queryString.parse(search),
         sort: '-operationDate',
      };
      const query = `?${queryString.stringify(parsedQuery)}`;

      const pagination = get(accountPlaceEntriesPagination, `${treasuryId}[${query}].idsByPage`) || {};

      const accountPlaceEntriesIds = pagination[page || 1] || [];

      return accountPlaceEntriesIds.map(id => accountPlaceEntryById[id]);
   }
);

export const selectTreasuryAccountPlaceEntriesPagination = createSelector(
   [getProps, getAccounts, getQuery, selectPlaceTreasuries],
   ({ target }, { accountPlaceEntriesPagination }, search, treasuries) => {
      const treasuryId = get(treasuries, `[${target}].id`);

      if (!treasuryId) {
         return {};
      }

      if (!search) {
         search = '?';
      }

      const { page, ...parsedQuery } = {
         ...queryString.parse(search),
         sort: '-operationDate',
      };
      const query = `?${queryString.stringify(parsedQuery || {})}`;

      return get(accountPlaceEntriesPagination, `${treasuryId}[${query}]`) || {};
   }
);

export const selectTreasuryInvoices = createSelector(
   [getAccounts, getQuery, getProps, selectPlaceTreasuries, getProps],
   ({ invoiceGroupById, invoiceById, invoicesPagination }, search, { target }, treasuries, props) => {
      const treasuryId = get(treasuries, `[${target}].id`);

      if (!treasuryId) {
         return [];
      }

      if (search === '') {
         search = '?';
      }

      const { page, ...parsedQuery } = pickBy({
         ...queryString.parse(search),
         search: (props.search || '').toLowerCase(),
      });

      const query = `?${queryString.stringify(parsedQuery)}`;

      const pagination = get(invoicesPagination, `${treasuryId}[${query}].idsByPage`) || {};

      const invoicesIds = pagination[page || 1] || [];

      return invoicesIds.map(id => invoiceGroupById[id]);
   }
);

export const selectTreasuryInvoicesPagination = createSelector(
   [getProps, getAccounts, getQuery, selectPlaceTreasuries, getProps],
   ({ target }, { invoicesPagination }, search, treasuries, props) => {
      const treasuryId = get(treasuries, `[${target}].id`);

      if (!treasuryId) {
         return {};
      }

      if (!search) {
         search = '?';
      }

      const { page, ...parsedQuery } = pickBy({
         ...queryString.parse(search),
         search: props.search,
      });
      const query = `?${queryString.stringify(parsedQuery || {})}`;

      return get(invoicesPagination, `${treasuryId}[${query}]`) || {};
   }
);

export const selectTreasuryCoownerAmounts = createSelector(
   [getAccounts, getQuery, getProps, selectPlaceTreasuries],
   ({ coownerAmountById, coownerAmountsPagination }, search, { target }, treasuries) => {
      const treasuryId = get(treasuries, `[${target}].id`);

      if (!treasuryId) {
         return [];
      }

      if (search === '') {
         search = '?';
      }

      const { page, ...parsedQuery } = queryString.parse(search);
      const query = `?${queryString.stringify(parsedQuery)}`;

      const pagination = get(coownerAmountsPagination, `${treasuryId}[${query}].idsByPage`) || {};

      const coownerAmountsIds = pagination[page || 1] || [];
      return coownerAmountsIds.map(id => coownerAmountById[id]);
   }
);

export const selectTreasuryCoownerAmountsPagination = createSelector(
   [getProps, getAccounts, getQuery, selectPlaceTreasuries],
   ({ target }, { coownerAmountsPagination }, search, treasuries) => {
      const treasuryId = get(treasuries, `[${target}].id`);

      if (!treasuryId) {
         return {};
      }

      if (!search) {
         search = '?';
      }

      const { page, ...parsedQuery } = queryString.parse(search);
      const query = `?${queryString.stringify(parsedQuery || {})}`;

      return get(coownerAmountsPagination, `${treasuryId}[${query}]`) || {};
   }
);

export const selectContact = createSelector([getUsers, getProps], ({ userById }, { id }) => userById[id]);

export const selectCurrentRole = createSelector([getSelectedPlace], place => get(place, 'topPlaceRole.kind'));

export const selectPlaceNotifications = createSelector(
   [getPlaces, getUi],
   ({ highlightedNotificationsIdsByPlaceId, notificationById }, { selectedPlaceId }) => {
      const notificationsIds = highlightedNotificationsIdsByPlaceId[selectedPlaceId] || [];

      return notificationsIds.map(id => notificationById[id]);
   }
);

export const selectPlaceRssFeeds = createSelector(
   [getPlaces, getUi],
   ({ rssFeedsIdsByPlaceId, rssFeedById }, { selectedPlaceId }) => {
      const rssFeedsIds = rssFeedsIdsByPlaceId[selectedPlaceId] || [];

      return rssFeedsIds.map(id => rssFeedById[id]);
   }
);

export const selectDistributionLists = createSelector(
   [getPlaces, getUi],
   ({ distributionListById, distributionListsIdsByPlaceId }, { selectedPlaceId }) =>
      (distributionListsIdsByPlaceId[selectedPlaceId] || []).map(id => distributionListById[id])
);

export const selectMaintenanceContractsNames = createSelector([getPlaces], ({ maintenanceContractById }) =>
   values(maintenanceContractById).map(({ id, companyName }) => ({
      id,
      displayName: companyName,
   }))
);

export const selectDocumentsNames = createSelector([getContracts], ({ contractDocumentsFolderById }) =>
   values(contractDocumentsFolderById).map(({ id, displayName }) => ({
      id,
      displayName,
   }))
);

export const selectLastPlaceDocuments = createSelector(
   [getContracts, getUi],
   ({ contractById, parcelById }, { selectedPlaceId }) =>
      values(contractById)
         .filter(({ placeId }) => placeId === selectedPlaceId)
         .filter(({ lastDocument }) => Boolean(lastDocument))
         .map(({ id, lastDocument, parcels, displayName }) => ({
            id,
            displayName,
            document: lastDocument,
            parcels: parcels.map(id => parcelById[id]),
         }))
);
